<template>
  <div class="context" style="height:100%">
    <!-- 头部 -->
    <div class="header">
      <!-- 搜索框 -->
      <el-input
        @keyup.enter.native="search"
        placeholder="请输入素材名称或关键字"
        v-model="searchValue"
      >
        <el-select
          @change="selectVideoandImg"
          class="search"
          v-model="videoOrimg"
          :popper-append-to-body="false"
          slot="prepend"
          placeholder="视频">
          <el-option label="视频" value="video"></el-option>
          <el-option label="图片" value="img"></el-option>
        </el-select>
      </el-input>
      <!-- 搜索图标按钮 -->
      <div class="searchButton">
        <i
          style="width: 25px; height: 32px; cursor: pointer; padding-top: 15px"
          class="iconfont icon-sousuo"
          @click="search"
        ></i>
      </div>
    </div>
    <div v-if="none==false" class="container" ref="middleListContent" v-loading="ifLoading"  element-loading-background="rgba(0, 0, 0, 0.1)" element-loading-text="素材加载中...">
       <!-- 视频内容区域 -->
    <div
      v-if="videoOrimg == 'video'"
      class="videoContext"
      ref="middleListContent"
     style="min-height:100%;display: flex;
    flex-direction: column;
    justify-content: space-between;"
    >
      <div>
        <!-- 统计跟排序 -->
      <div class="totalORorder">
        <div class="total">共{{ videoTotal }}个素材</div>
      </div>
      <!-- 视频区域 -->
      <div v-if="noResult==0" class="allVideo">
        <div class="video" v-for="(item , index ) in videoData" :key="item.ip_id">
          <!-- 视频 -->
          <div
            class="videoSize"
            style="cursor: pointor"
            :id="item.ip_id" @mouseleave="imgLeave"  @mouseenter="imgEnter"
            @click="goMaterialDetail(item.ip_id)"
          >
              <img
                  :class="['gifNode',item.gifNode ? 'gifNode_show':'']"
                  style="cursor: pointor"
                  :src="item.gifNode"
                  alt=""
                />
                <img
                 class="imgNode"
                  style="cursor: pointor"
                  :src="item.cover_img"
                  v-if="item.showImg"
                  alt=""
                />
                <i class="iconfont icon-shipin1" v-if="!item.cover_img"></i>
          </div>
          <!-- 下载,加入观看，收藏列表 -->
          <div
            class="btns"
          >
            <img
              @click="download(item.url,item.ip_id)"
              class="download"
              src="@/assets/images/down.png"
              alt=""
            />
            <img
              class="add"
              src="@/assets/images/add.png"
              alt=""
              @click="addList(item.ip_id)"
            />
            <!-- 收藏按钮 -->
            <div @click="addCollet(item.ip_id)">
              <img
                class="collet"
                v-if="collet"
                src="@/assets/images/colleted.png"
              />
              <img class="collet" v-else src="@/assets/images/collet.png" />
            </div>
          </div>
          <!-- 视频时长 -->
          <span class="video_duration">{{ item.duration }}</span>
          <!-- 视频底部标题 -->
       <el-tooltip
          v-delTabIndex
          :placement="!filterNum(index +1) && item.name.length > 24 ?'bottom-end':'bottom-start'"
          popper-class="my_material_tooltip"
          :content="item.name"
        >
          <div
            class="videoTitle one_line_ellipsis"
            @click="goMaterialDetail(item.ip_id)"
          >
            {{ item.name }}
          </div>
        </el-tooltip>
        </div>
      </div>
      <noResult style="margin-top:15%" v-if="noResult==2" iconUrl="icon-meiyoushoucang" iconSty="font-size:80px" textSty="font-size:18px" noResultText="没有符合条件的素材"></noResult>
      </div>
      <!-- 分页 -->
      <el-pagination
       :background="true"
      :current-page="videopage"
      v-show="noResult==0&&ifLoading==false"
        @current-change="handleCurrentChange"
        layout="prev, pager, next, jumper"
        :page-size="40"
        :total="videoOrimg == 'video' ? videoTotal : imgTotal"
        :hide-on-single-page="true"
      >
      </el-pagination>
    </div>
    <!-- 图片内容区域 -->
    <div
      v-if="videoOrimg == 'img'"
      class="videoContext"
      ref="middleListContent"
      style="min-height:100%;display: flex;
    flex-direction: column;
    justify-content: space-between;"
    >
     <div>
        <!-- 统计跟排序 -->
      <div class="totalORorder">
        <div class="total">共{{ imgTotal }}个素材</div>
      </div>
      <!-- 图片区域 -->
      <div class="allImg" v-if="noResult==0">
        <div class="video" v-for="(item,index) in imgData" :key="item.ip_id">
          <!-- 图片 -->
          <div class="videoSize img_size" @click="goMaterialDetail(item.ip_id)">
            <img :src="item.cover" alt="" />
          </div>
          <!-- 下载,收藏按钮 -->
          <div class="btns">
            <!-- 下载按钮 -->
            <img
              class="download"
              src="@/assets/images/down.png"
              alt=""
              @click="download(item.url, item.name)"
            />
            <!-- 收藏按钮 -->
            <div @click="addCollet(item.ip_id)">
              <img
                class="collet"
                v-if="collet"
                src="@/assets/images/colleted.png"
              />
              <img class="collet" v-else src="@/assets/images/collet.png" />
            </div>
          </div>
          <!-- 图片底部标题 -->
        <el-tooltip
               :placement="!filterNum(index +1) && item.name.length > 24 ?'bottom-end':'bottom-start'"
               popper-class="my_material_tooltip"
                :content="item.name"
              >
                <div
                  class="videoTitle one_line_ellipsis"
                  @click="goMaterialDetail(item.ip_id)"
                >
                  {{ item.name }}
                </div>
              </el-tooltip>
        </div>
      </div>
      <noResult style="margin-top:15%" v-if="noResult==2" iconUrl="icon-meiyoushoucang" iconSty="font-size:80px" textSty="font-size:18px" noResultText="没有符合条件的素材"></noResult>
     </div>
      <!-- 分页 -->
      <el-pagination
       :background="true"
      :current-page="videopage"
     v-show="noResult==0&&ifLoading==false"
        @current-change="handleCurrentChange"
        layout="prev, pager, next, jumper"
        :page-size="40"
        :total="videoOrimg == 'video' ? videoTotal : imgTotal"
        :hide-on-single-page="true"
      >
      </el-pagination>
    </div>
    </div>
    <noResult v-else iconUrl="icon-meiyoushoucang" iconSty="font-size:80px" textSty="font-size:18px" noResultText="暂时没有素材"></noResult>
    <!-- 下载提示对话框 -->
    <el-dialog
      title="下载"
      :visible.sync="downloadDialog"
      width="461px"
      top="30vh"
      custom-class="material-dialog"
      :show-close="true"
      :before-close="downloadClose"
    >
      <span style="display: block; margin-bottom: 21px">分辨率：</span>
      <div class="radio_check">
        <el-radio label="标清" v-if="resolutions.length == 1" class="disable_radio">标清</el-radio>
        <el-radio
          v-for="(item, index) in resolutions"
          :key="index"
          v-model="system"
          :label="item.url"
          >{{ item.resolution }}</el-radio
        >
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="downloadConfirm">确 定</el-button>
        <el-button @click="downloadDialog = false" style="margin-right: 30px"
          >取 消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Player from "xgplayer";
import { preventSpaceScroll } from "../plugins/solvemethod";
import noResult from '../component/noResult.vue'
import $ from "jquery";

export default {
  inject: ["reload"],
  components: {
      noResult,
  },
  data() {
    return {
      //做选择视频跟选择图片的缓存
      videoOrimg: this.getCache(),
      // 是否已经收藏
      collet: true,
      // 显示收藏下载等按钮
      showbtns: false,
      // 视频总计
      videoTotal: 0,
      // 图片总计
      imgTotal: 0,
      // 视频数据
      videoData: [],
      // 图片数据
      imgData: [],
      // 搜索框绑定的值
      searchValue: "",
      // 显示隐藏下载对话框
      downloadDialog: false,
      // 下载对话框选中的选项
      system: "",
      // 视频的下载源跟图片的下载源
      downSourse: "",
      resolutions: [], //下载的分辨率
      ifLoading:true,
      noResult:0,//没有符合条件的素材
      none:false,//素材为空
      videopage:0,
    };
  },
  methods: {
    filterNum (n) {
      if (n % 5 == 1 || n % 5 == 2 || n % 5 == 3 ) {
        return true
      }else{
        return false
      }
    },
    // 搜索
    search() {
      if (this.searchValue.trim() == "") {
        this.$message.error("请输入素材名称或关键字");
      } else {
        this.videopage=1
        // 关键词搜索视频
        if (this.videoOrimg == "video") {
          // 设置请求参数
          let formData = { type: 1, page: 1, search_name: this.searchValue };
          this.getVideo(formData,'1');
        }
        // 关键词搜索图片
        if (this.videoOrimg == "img") {
          // 设置请求参数
          let formData = { type: 2, page: 1, search_name: this.searchValue };
          this.getImg(formData,'1');
        }
      }
    },
    //做选择视频跟选择图片的缓存
    getCache() {
      // 如果缓存为空，默认视频video
      if (sessionStorage.getItem("videoOrimg1") == null) {
        return "video";
        //如果缓存不为空,默认图片 img
      } else {
        return sessionStorage.getItem("videoOrimg1");
      }
    },
    //跳转至素材详情
    goMaterialDetail(id) {
      const { href } = this.$router.resolve({
        name: "materialDetail",
        query: { ip_id: id, videoOrimg: this.videoOrimg },
      });
      window.open(href, "_blank");
    },
    // 选择视频或者图片
    selectVideoandImg(e) {
      // 清空搜索框值
      this.searchValue = "";
      // 选择视频
      if (e == "video") {
        // 做缓存
        sessionStorage.setItem("videoOrimg1", "video");
        // 设置请求参数
        let formData = { type: 1, page: 1 };
        this.getVideo(formData);
      }
      //选择图片
      if (e == "img") {
        // 做缓存
        sessionStorage.setItem("videoOrimg1", "img");
        // 设置请求参数
        let formData = { type: 2, page: 1 };
        this.getImg(formData);
      }
    },
     //  视频鼠标移入
    imgEnter(e) {
      this.videoData.forEach((item,i)=>{
      item.gifNode = ''
      if(e.target.id == item.ip_id){
        item.gifNode = item.cover
        item.imgNode = ''
        item.showImg = false
      }
      })

    },
    // 视频鼠标移出
    imgLeave(e) {
       this.videoData.forEach((item,i)=>{
        item.gifNode = ''
        item.showImg = true
      })
    },
    // 取消收藏
    addCollet(id) {
      let tp = this.videoOrimg == "video" ? 1 : 2;
      let formData = new FormData();
      formData.append("ip_id", id);
      formData.append("type", tp);
      this.$http({
        method: "POST",
        url: "vw_material/add_my_collect",
        data: formData,
      }).then((res) => {
        if (tp == 1) {
          // 设置请求参数 请求所有收藏列表
          let formData = { type: 1, page: 1 };
          this.getVideo(formData);
        }
        if (tp == 2) {
          // 设置请求参数
          let formData = { type: 2, page: 1 };
          this.getImg(formData);
        }
      }).catch(error => console.log(error));
    },
    // 添加播放列表事件
    addList(id) {
      let formData = new FormData();
      formData.append("ip_id", id);
      this.$http({
        method: "POST",
        url: "vw_material/add_my_watch_record",
        data: formData,
      }).then((res) => {
        // console.log(res);
        // 加入成功
        if (res.data.status == 0) {
          this.$store.commit("editAdd", true);
          setTimeout(() => {
            this.$store.commit("editAdd", false);
          }, 2000);
        }
      }).catch(error => console.log(error));
    },
    // 下载按钮
    download(downSourse, name) {
      // 将下载源保存
      this.downSourse = downSourse;
      // 如果是视频
      if (this.videoOrimg == "video") {
        let formData = new FormData();
        formData.append("ip_id", name);
        this.$http({
          url: "/upload/get_download_res",
          method: "POST",
          data: formData,
        }).then((res) => {
          // console.log(res.data);
          if (res.data.status == 0) {
            this.resolutions = res.data.result;
            this.system = res.data.result[0].url;
            // 弹出下载框
            this.downloadDialog = true;
          }
        }).catch(error => console.log(error));
      }
      //如果是图片
      if (this.videoOrimg == "img") {
        this.downloadImg(this.downSourse, name);
      }
    },
    // 下载对话框确认事件
    downloadConfirm() {
      // 切换视频配置源 然后视频下载。
      let link = document.createElement('a');
      link.href = this.system  //链接
      document.body.appendChild(link) //a标签加到body
      link.click(); //模拟a标签点击
      document.body.removeChild(link) // 下载完成移除元素
      this.downloadDialog = false;
    },
    // 下载图片 传入下载地址
    downloadImg(imgsrc, name) {
      let download_name = ''
      let link = document.createElement('a');
      link.download = download_name  // 下载文件的名字
      // console.log(download_name)
      link.href = imgsrc  //链接
      document.body.appendChild(link) //a标签加到body
      link.click(); //模拟a标签点击
      document.body.removeChild(link) // 下载完成移除元素
    },
    // 获取视频
    getVideo(formData,log) {
      this.ifLoading = true
      // 请求视频
      this.$http({
        url: "vw_material/mycollectlist",
        method: "GET",
        params: formData,
      }).then((res) => {
        // console.log("视频", res);
        // 成功
        if (res.data.status == 0) {
          this.videoTotal = res.data.total;
              // 当前没有符合条件的素材
          if(log=='1' && res.data.result.length==0){
            this.ifLoading = false
              this.noResult=2
              return
          }
          // 根本没素材
          if(res.data.result.length==0){
            this.ifLoading = false
              this.none=true
              return
          }
           this.noResult=0
          this.none=false
           this.ifLoading = false
           res.data.result.forEach((item,i)=>{
              item.gifNode = ''
              item.showImg = true
            })
          this.videoData = res.data.result;
          // console.log(this.videoData)
        }
      }).catch(error => console.log(error));
    },
    // 获取图片
    getImg(formData,log) {
      this.ifLoading = true
      this.$http({
        url: "vw_material/mycollectlist",
        method: "GET",
        params: formData,
      }).then((res) => {
        // console.log("图片", res);
        // 成功
        if (res.data.status == 0) {
          this.imgTotal = res.data.total;
           // 当前没有符合条件的素材
          if(log=='1' && res.data.result.length==0){
             this.ifLoading = false
              this.noResult=2
              return
          }
          if(res.data.result.length==0){
            this.ifLoading = false
              this.none=true
              return
          }
          this.noResult=0
          this.none=false
           this.ifLoading = false

          this.imgData = res.data.result;
        }
      }).catch(error => console.log(error));
    },
    // 下载对话框的关闭
    downloadClose(done) {
      this.downloadDialog = false;
    },
    // 选择某一页
    handleCurrentChange(val) {
      this.$nextTick(() => {
        this.$refs.middleListContent.scrollTop = 0;
      });
      // 搜索当前页的数据
      if (this.videoOrimg == "video") {
        // 设置请求参数
        let formData = {
          type: 1,
          page: val,
        };
        this.getVideo(formData);
      }
      // 关键词搜索图片
      if (this.videoOrimg == "img") {
        // 设置请求参数
        let formData = {
          type: 2,
          page: val,
        };
        this.getImg(formData);
      }
    },
  },
  created() {
    this.$store.commit("myCollectionPage", false);
    // 获取我的收藏列表的视频数据
    if (this.getCache() == "video") {
      // 设置请求参数
      let formData = { type: 1, page: 1 };
      this.getVideo(formData);
    }
    // 获取我的收藏列表的图片数据
    if (this.getCache() == "img") {
      // 设置请求参数
      let formData = { type: 2, page: 1 };
      this.getImg(formData);
    }
  },
  watch: {
    videoOrimg() {
      // console.log(this.videoOrimg);
      if (this.videoOrimg == "img") {
        this.$nextTick(() => {
          $(".img_size").each((i, item) => {
            let video_boxW = $(item).width();
            $(item).css("height", video_boxW * 0.587 + "px");
          });
        });
      }
    },
    myCollectionPage(val) {
      if (val == true) {
        this.reload();
      }
    },
  },
  computed: {
    myCollectionPage: {
      get() {
        return this.$store.state.myCollection;
      },
      set(val) {
        // this.$store.commit('myCollectionPage', val)
      },
    },
  },
  mounted() {
    window.onresize = () => {
      $(".img_size").each((i, item) => {
        let video_boxW = $(item).width();
        $(item).css("height", video_boxW * 0.587 + "px");
      });
    };
    preventSpaceScroll($(".videoContext"));
  },

  beforeDestroy() {
    sessionStorage.removeItem("videoOrimg1");
  },
  destroyed() {
    this.$store.commit("myCollectionPage", false);
  },
};
</script>

<style lang='scss' scoped>
// 头部搜索框
.header {
  height: 92px;
  background: #161626;
  // margin-top: 5px;
  display: flex;
  justify-content: center;
  .el-input-group {
    line-height: normal;
    display: inline-table;
    width: 55vw;
    border-collapse: separate;
    border-spacing: 0;
    margin-top: 20px;
    height: 50px;
  }
  /deep/.el-input-group__prepend {
    border-right: 0;
    width: 59px;
    height: 48px;
    background: #2D2D3D;
    opacity: 1;
    border-radius: 6px 0px 0px 6px;
    border: 0px solid #dcdfe6;
    color: rgba(255, 255, 255, 0.9);
     .el-popper[x-placement^=bottom] {
     margin-top: 1px;
    }
  }
  /deep/.el-input__inner {
    height: 50px;
    background: #212130;
    border: 0px solid;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    color: rgba(255, 255, 255, 0.9);
  }

  /deep/.el-input__suffix {
    right: 20px;
    transition: all 0.3s;
    pointer-events: none;
  }
  // 图片视频下拉框切换
  /deep/.el-select-dropdown {
    left: 0px !important;
    height:60px !important;
    border: 0px solid;
    background: #2D2D3D;
    .el-scrollbar{
      border-radius: 5%;
    }
    .el-select-dropdown__list{
      padding: 0px 0;
    }
    .el-select-dropdown__item {
      background: #2D2D3D;
      color: #f7f7f7;
      font-size: 14px;
      font-family: Microsoft YaHei;
      height: 30px;
      line-height: 30px;
      padding: 0 15px;
    }
    .el-select-dropdown__item.hover {
      background: unset;
      font-size: 14px;
      font-family: Microsoft YaHei;
      color: #2a9cff;
    }
    .selected {
      color: #2a9cff;
      font-size: 14px;
      font-family: Microsoft YaHei;
    }
  }

  .searchButton {
    margin-top: 20px;
    height: 50px;
    background: #212130;
    border-radius: 0px 6px 6px 0px;
    padding-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
// 统计跟排序
.totalORorder {
  display: flex;
  justify-content: space-between;
  .total {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 20px;
    color: #ffffff;
    opacity: 0.6;
    margin-top: 47px;
    margin-left: 3vw;
  }
}
// 视频区域
.allVideo {
  margin-top: 28px;
  .video {
    // position: relative;
    // margin-left: 28px;
    // margin-bottom: 61px;
    position: relative;
    // margin-left: 28px;
    display: inline-block;
    width: 17%;
    margin: 0 1%;
    margin-bottom: 25px;
    &:nth-child(5n + 1) {
      margin-left: 3.5%;
    }
    &:nth-child(5n + 5) {
      margin-right: 3.5%;
    }
    .video_duration {
      position: absolute;
      bottom: 30px;
      right: 2px;
      z-index: 99;
      color: #fff;
      font-size: 12px;
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
      padding: 4px 6px;
    }
    .videoSize {
      width: 100%;
      height: 160px;
      overflow: hidden;
      background: #000;
      display: flex;
      justify-content: center;
      align-items: center;
      .gifNode{
        width: 0;
        height: 0;
      }
      .gifNode_show{
        width: auto;
        height: auto;
      }
      img {
        max-width: 100%;
        max-height: 100%;
        cursor:pointer;
        flex-shrink: 0;
      }
    }
    .xgplayer-skin-custom video {
      max-width: 281.03px;
      max-height: 160px;
    }
    .btns {
      display: none;
    }
    /deep/.xgplayer-skin-custom .xgplayer-volume {
      display: none;
    }
    /deep/.xgplayer-skin-custom .xgplayer-controls {
      top: 0;
      left: 0;
      right: 0;
      height: 40px;
      background-image: none;
      z-index: 10;
    }
    /deep/.xgplayer-skin-custom.xgplayer-nostart .xgplayer-controls {
      display: block;
    }
    /deep/.xgplayer-skin-custom .xgplayer-progress-outer {
      background: rgba(42, 156, 255, 0.6);
      display: block;
      height: 8px;
      line-height: 8px;
      margin-top: 0px;
      width: 281px;
      position: relative;
      cursor: pointer;
    }
    /deep/.xgplayer-skin-custom .xgplayer-progress {
      display: none;
      position: absolute;
      height: 8px;
      line-height: 8px;
      left: 0px;
      right: 0px;
      outline: none;
      top: 143px;
      z-index: 35;
      width: 281px;
    }
    /deep/.xgplayer-skin-custom .xgplayer-progress-cache {
      background: rgba(42, 156, 255, 0.6);
      width: 0 !important;
    }
    /deep/.xgplayer-skin-custom .xgplayer-progress-played {
      display: block;
      width: 0;
      background: #2a9cff;
      border-radius: 0 1.5px 1.5px 0;
    }

    /deep/.xgplayer-skin-custom .xgplayer-progress-btn {
      display: block;
      top: -3px;
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background: #2a9cff;
      border: 3px solid #ffffff;
    }
    /deep/.xgplayer-skin-custom .xgplayer-download {
      position: relative;
      order: 9;
      display: none;
      cursor: pointer;
    }
    /deep/.xgplayer-skin-custom.xgplayer-pause .xgplayer-start {
      display: none;
    }
    /deep/.xgplayer-skin-custom .xgplayer-start {
      display: none;
    }
    /deep/.xgplayer-skin-custom .xgplayer-time .xgplayer-time-current {
      display: none;
    }
    /deep/.xgplayer-skin-custom .xgplayer-time {
      order: 2;
      font-family: Microsoft YaHei;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.9);
      line-height: 23px;
      display: block;
      width: 52px;
      height: 23px;
      background: rgba(0, 0, 0, 0.5);
      opacity: 1;
      border-radius: 5px;
      position: absolute;
      margin-top: 135px;
      float: bottom;
      margin-left: 229px;
    }
  }
  .video:hover {
    .btns {
      position: absolute;
      display: flex;
      justify-content: flex-end;
      top: 0;
      right: 0px;
      z-index: 99;
      .download {
        width: 24px;
        height: 24px;
        background: rgba(0, 0, 0, 0.5);
        opacity: 1;
        border-radius: 5px;
        margin-top: 9px;
        margin-right: 5px;
        cursor: pointer;
      }
      .add {
        width: 24px;
        height: 24px;
        background: rgba(0, 0, 0, 0.5);
        opacity: 1;
        border-radius: 5px;
        margin-top: 9px;
        margin-right: 5px;
        cursor: pointer;
      }
      .collet {
        width: 24px;
        height: 24px;
        background: rgba(0, 0, 0, 0.5);
        opacity: 1;
        border-radius: 5px;
        margin-top: 9px;
        margin-right: 7px;
        cursor: pointer;
      }
    }
    .video_duration{
      display:none
    }
    /deep/.xgplayer-skin-custom .xgplayer-progress {
      display: block;
      position: absolute;
      height: 8px;
      line-height: 8px;
      left: 0px;
      right: 0px;
      outline: none;
      top: 143px;
      z-index: 35;
      width: 281px;
    }
    /deep/.xgplayer-skin-custom .xgplayer-time {
      display: none;
    }
  }
}
.container {
  overflow-y: auto;
  height: 83vh;
  /deep/.el-loading-spinner .circular{
    width: 36px;
    height: 36px;
  }
  /deep/.el-loading-spinner .path{
    stroke: #fff;
    stroke-width: 3;
  }
  /deep/.el-loading-spinner .el-loading-text{
    color: #fff;
    margin-top: 10px;
  }
}
// 内容区域滚动条样式
.container::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 4px;
}
.container::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 7px;

  background: #b9bac9;
}
.container::-webkit-scrollbar-track {
  /*滚动条里面轨道*/

  border-radius: 7px;
  background: #5e5d6e;
}
// 图片区域
.allImg {
  margin-top: 28px;
  .video {
    position: relative;
    // margin-left: 28px;
    display: inline-block;
    width: 17%;
    margin: 0 1%;
    margin-bottom: 25px;
    &:nth-child(5n + 1) {
      margin-left: 3.5%;
    }
    &:nth-child(5n + 5) {
      margin-right: 3.5%;
    }
  }
  .videoSize {
    width: 100%;
    height: 160px;
    overflow: hidden;
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 100%;
      max-height: 100%;
       cursor:pointer;
       flex-shrink: 0;
    }
  }
  .btns {
    display: none;
  }
  .video:hover {
    .btns {
      position: absolute;
      display: flex;
      justify-content: flex-end;
      top: 0;
      right: 0px;
      z-index: 99;
      .download {
        width: 24px;
        height: 24px;
        background: rgba(0, 0, 0, 0.5);
        opacity: 1;
        border-radius: 5px;
        margin-top: 9px;
        margin-right: 5px;
        cursor: pointer;
      }
      .add {
        width: 24px;
        height: 24px;
        background: rgba(0, 0, 0, 0.5);
        opacity: 1;
        border-radius: 5px;
        margin-top: 9px;
        margin-right: 5px;
        cursor: pointer;
      }
      .collet {
        width: 24px;
        height: 24px;
        background: rgba(0, 0, 0, 0.5);
        opacity: 1;
        border-radius: 5px;
        margin-top: 9px;
        margin-right: 7px;
        cursor: pointer;
      }
    }
    /deep/.xgplayer-skin-custom .xgplayer-progress {
      display: block;
      position: absolute;
      height: 8px;
      line-height: 8px;
      left: 0px;
      right: 0px;
      outline: none;
      top: 143px;
      z-index: 35;
      width: 281px;
    }
    /deep/.xgplayer-skin-custom .xgplayer-time {
      display: none;
    }
  }
}
.videoTitle {
  cursor: pointer;
  margin-top: 12px;
  width: 100%;
  &:hover {
    color: #2a9cff;
  }
}
// 分页样式
.el-pagination {
  text-align: center;
  padding: 1vh 5px 0px 5px;
  /deep/.btn-prev {
    background: #292833;
    color: #fff;
  }
  /deep/.btn-next {
    background: #292833;
    color: #fff;
  }
  /deep/.el-pager li {
    background: #292833;
    color: #fff;
  }
  /deep/.el-pager li.active {
    color: #fff;
    background: #409eff;
    border-radius: 20%;
  }
  /deep/.el-pagination__jump {
    color: #fff;
  }
  /deep/.el-input__inner {
    background: #292833;
    color: #fff;
    border: 1px solid #3e3d4c;
  }
}
</style>
<style lang='scss'>
.my_material_tooltip.el-tooltip__popper{
    padding: 6px 10px;
    // max-width: 10%;
    // line-height: 18px;
  }
// 下载对话框
.el-dialog.material-dialog{
      background-color: #292939;
      border-radius: 15px;
      box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.16);
      padding: 8px 21px 21px;
      .el-dialog__header{
        text-align: center;
        padding: 0;
        padding-bottom: 6px;
        .el-dialog__title{
          color: #fff;
          font-size: 16px;
          letter-spacing: 30px;
        }
        .el-dialog__headerbtn{
          top: 10px;
          .el-dialog__close{
            color: #fff;
            font-size: 18px;
          }
        }
      }
      .el-dialog__body{
        color: #fff;
        background-color: #0F0F1E;
        font-size: 14px;
        padding: 38px 69px 0 69px;
        .radio_check{
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .disable_radio{
            .el-radio__inner{
              background-color: rgba(255,255,255,0.5);
              border: inherit;
            }
            .el-radio__label{
              color: rgba(255,255,255,0.5);
            }
          }
          .el-radio{
            width: 50%;
            margin-right: 0;
            margin-bottom: 18px;
            color: #fff;
          }
          .el-radio__inner{
            width: 16px;
            height: 16px;
          }
          .is-checked{
            .el-radio__inner{
              width: 16px;
              height: 16px;
              border: 3px solid #fff;
              &::after{
                background-color: inherit;
              }
            }
          }
        }
      }
      .el-dialog__footer{
        padding: 20px 69px 45px 69px;
        background-color: #0F0F1E;;
        border-radius: 0px 0px 10px 10px;
        .dialog-footer{
          display: flex;
          justify-content: space-between;
          .el-button{
            border-radius: 20px;
            border: 1px solid #5E5F72;
            background-color: #0F0F1E;;
            color: #fff;
            padding: 6px 15px;
          }
        }
      }
  }
</style>
